<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">编辑数据权限({{ interfaceName }})</span>
					<span v-else>添加数据权限({{ interfaceName }})</span>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('Name')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-input :placeholder="l('Name')" v-model="entity.name" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('过滤条件')"> </q-label>
					</a-col>
					<a-col :span="20">
						<div class="symbol" v-for="(item, index) in dataConditionList" :key="index">
							<div class="symbol-items">
								<span class="items-short">{{ item.sort }}</span>
								<span
									class="items-text ellipsis"
									:title="
										'【' +
											getInterfaceFieldValue(item.interfaceFieId) +
											'】' +
											getSymbolValue(item.symbol) +
											getFiledValueTypeValue(item)
									"
								>
									【{{ getInterfaceFieldValue(item.interfaceFieId) }}】
									{{ getSymbolValue(item.symbol) }} {{ getFiledValueTypeValue(item) }}
								</span>
							</div>
							<div class="symbol-btns">
								<div class="btn-edit" @click="symbolEdit(item, index)">编辑</div>
								<div class="btn-delete" @click="symbolDelete(index)">删除</div>
							</div>
						</div>
						<div>
							<a @click="addSymbol">添加更多条件</a>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('公式')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-col :span="20">
							<a-input :placeholder="l('公式')" v-model="entity.formula" />
						</a-col>
						<a-col :span="4">
							<div style="width: 100%;">
								<a-tooltip placement="top">
									<template slot="title">
										<span>公式示例:1 and 2 or (3 or 4)</span>
									</template>
									<div style="width: 60%;padding-left: 5px;cursor: pointer">
										<a-icon type="info-circle" />
									</div>
								</a-tooltip>
							</div>
						</a-col>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { ModalComponentBase } from '../../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import modalHelper from '../../../../../shared/helpers/modal/modal-helper';
import CreateOrEditSymbol from './create-or-edit-symbol/create-or-edit-symbol';
import {
	CreateOrUpdateDataRelationInput,
	DataConditionServiceProxy,
	DataRelationEditDto,
	DataRelationServiceProxy,
	InterfaceFieIdServiceProxy,
} from '../../../../../shared/service-proxies';

export default {
	name: 'create-or-edit-interface-authorize',
	mixins: [ModalComponentBase],
	components: { CreateOrEditSymbol },
	data() {
		return {
			zh_CN,
			//类型1、角色 2、用户
			type: undefined,
			//对应的角色或用户Id
			ObjectId: undefined,
			id: undefined,
			interfaceId: undefined,
			interfaceName: undefined,
			dataConditionList: [],
			interfaceFieldList: [],
			symbolList: [],
			filedValueTypeList: [],
			entity: new DataRelationEditDto(),
		};
	},
	created() {
		this.fullData();
		this._dataConditionServiceProxy = new DataConditionServiceProxy(this.$apiUrl, this.$api);
		this._interfaceFieIdServiceProxy = new InterfaceFieIdServiceProxy(this.$apiUrl, this.$api);
		this._dataRelationServiceProxy = new DataRelationServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		try {
			this.loading = true;
			this.interfaceFieldList = await this.getInterfaceFieldList();
			this.symbolList = await this.getConditionDataDic(1);
			this.filedValueTypeList = await this.getConditionDataDic(2);
		} catch (e) {
			console.log(e);
			this.loading = false;
		} finally {
			this.loading = false;
		}
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._dataRelationServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.dataRelation;
					this.dataConditionList = res.dataConditionList;
				});
		},
		/**
		 * 添加规则
		 */
		addSymbol() {
			modalHelper
				.create(
					CreateOrEditSymbol,
					{
						interfaceId: this.interfaceId,
					},
					{ width: '500px' }
				)
				.subscribe((res) => {
					if (res) {
						this.dataConditionList = [...this.dataConditionList, res.data];
						this.dataConditionList.map((item, index) => {
							item.sort = index + 1;
						});
					}
				});
		},
		/**
		 * 编辑过滤条件
		 */
		symbolEdit(record, index) {
			modalHelper
				.create(
					CreateOrEditSymbol,
					{
						interfaceId: this.interfaceId,
						entity: record,
						index: index,
					},
					{ width: '500px' }
				)
				.subscribe((res) => {
					if (res) {
						this.dataConditionList[res.index] = res.data;
						this.dataConditionList.map((item, index) => {
							item.sort = index + 1;
						});
					}
				});
		},
		/**
		 * 重置公式排序
		 */
		symbolShort() {
			this.dataConditionList.map((item, index) => {
				item.sort = index + 1;
			});
		},
		/**
		 * 删除过滤条件
		 */
		symbolDelete(index) {
			this.dataConditionList = [...this.dataConditionList.filter((item, i) => i != index)];
			this.symbolShort();
		},
		/**
		 * 获取选择字段
		 */
		getInterfaceFieldValue(key) {
			let d = this.interfaceFieldList.filter((item) => item.id == key);
			if (d.length > 0) {
				return d[0].describe;
			}
			return '';
		},
		/**
		 * 获取比较符值
		 */
		getSymbolValue(key) {
			let d = this.symbolList.filter((item) => item.key == key);
			if (d.length > 0) {
				return d[0].value;
			}
			return '';
		},
		/**
		 * 获取字段值类型
		 */
		getFiledValueTypeValue(record) {
			if (record.filedValueType != 1) {
				let d = this.filedValueTypeList.filter((item) => item.key == record.filedValueType);
				if (d.length > 0) {
					return d[0].value;
				}
			} else {
				return record.filedValue;
			}
			return '';
		},
		/**
		 * 获取接口相关字段
		 */
		getInterfaceFieldList() {
			return new Promise((resolve) => {
				this._interfaceFieIdServiceProxy.getInterfaceFieldListByInterfaceId(this.interfaceId).then((res) => {
					resolve(res);
				});
			});
		},
		/**
		 * 获取条件相关数据
		 * @param type
		 * @return {Promise<unknown>}
		 */
		getConditionDataDic(type) {
			return new Promise((resolve) => {
				this._dataConditionServiceProxy.getConditionDataDic(type).then((res) => {
					resolve(res);
				});
			});
		},
		save() {
			if (!this.entity.name) {
				return abp.message.warn('名称不能为空');
			}
			if (this.dataConditionList.length <= 0) {
				return abp.message.warn('请至少添加一条过滤条件');
			}
			this.entity.objectId = this.ObjectId;
			this.entity.objectType = this.type;
			this.entity.interfaceId = this.interfaceId;

			let input = new CreateOrUpdateDataRelationInput();
			input.dataRelation = this.entity;
			input.dataConditionList = this.dataConditionList;
			this.loading = true;
			this._dataRelationServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.symbol {
	width: 100%;
	height: 35px;
	display: flex;
	margin-bottom: 5px;
}

.symbol-items {
	flex: 6;
	margin-right: 5px;
	border-radius: 3px 0px 0px 3px;
	border: 1px solid #cccccc;
	display: flex;
	align-items: center;
}

.items-short {
	width: 35px;
	background-color: #e9e9e9;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35px;
	border-bottom: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
}

.items-text {
	height: 35px;
	padding-left: 10px;
}

.symbol-btns {
	flex: 2;
	height: 35px;
	display: flex;
	border: 1px solid #cccccc;
	border-radius: 3px;
	justify-content: center;
	align-items: center;
}

.btn-edit {
	border-right: 1px solid #cccccc;
}

.btn-edit,
.btn-delete {
	height: 35px;
	cursor: pointer;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-delete:hover {
	background-color: #e9e9e9;
	border-bottom: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
}

.btn-edit:hover {
	background-color: #e9e9e9;
	border-bottom: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
}

.ellipsis {
	max-width: 290px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
</style>
