<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="!index">编辑过滤条件</span>
					<span v-else>添加过滤条件</span>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="6">
						<q-label required :label="l('选择字段')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-select
							show-search
							:placeholder="l('选择字段')"
							option-filter-prop="children"
							v-model="entity.interfaceFieId"
							style="width: 100%"
						>
							<a-select-option v-for="item in interfaceFieldList" :key="item.id">
								{{ item.describe }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<q-label required :label="l('比较符')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-select
							show-search
							:placeholder="l('比较符')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.symbol"
						>
							<a-select-option v-for="item in symbolList" :key="item.key">
								{{ item.value }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<q-label required :label="l('字段值类型')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-select
							show-search
							:placeholder="l('字段值类型')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.filedValueType"
							@change="filedValueTypeChange"
						>
							<a-select-option v-for="item in filedValueTypeList" :key="item.key">
								{{ item.value }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<q-label :required="entity.filedValueType == 1" :label="l('字段值')"> </q-label>
					</a-col>
					<a-col :span="18">
						<a-input
							:placeholder="FTplaceholder"
							:disabled="entity.filedValueType != 1"
							v-model="entity.filedValue"
						/>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { ModalComponentBase } from '../../../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import {
	DataConditionEditDto,
	DataConditionServiceProxy,
	InterfaceFieIdServiceProxy,
} from '../../../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'create-or-edit-symbol',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			interfaceId: undefined,
			interfaceFieldList: [],
			symbolList: [],
			filedValueTypeList: [],
			entity: new DataConditionEditDto(),
			index: undefined,
			FTplaceholder: '字段值',
		};
	},
	created() {
		this.fullData();
		this._dataConditionServiceProxy = new DataConditionServiceProxy(this.$apiUrl, this.$api);
		this._interfaceFieIdServiceProxy = new InterfaceFieIdServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		try {
			this.loading = true;
			this.interfaceFieldList = await this.getInterfaceFieldList();
			this.symbolList = await this.getConditionDataDic(1);
			this.filedValueTypeList = await this.getConditionDataDic(2);
		} catch (e) {
			console.log(e);
			this.loading = false;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		/**
		 * 获取接口相关字段
		 */
		getInterfaceFieldList() {
			return new Promise((resolve) => {
				this._interfaceFieIdServiceProxy.getInterfaceFieldListByInterfaceId(this.interfaceId).then((res) => {
					resolve(res);
				});
			});
		},
		filedValueTypeChange() {
			if (this.entity.filedValueType != 1) {
				this.entity.filedValue = undefined;
			}
			switch (this.entity.filedValueType) {
				case 1:
					this.FTplaceholder = '字段值';
					break;
				case 2:
					this.FTplaceholder = '当前用户ID';
					break;
				case 3:
					this.FTplaceholder = '当前用户账号';
					break;
				case 4:
					this.FTplaceholder = '当前用户公司';
					break;
				case 5:
					this.FTplaceholder = '当前用户部门';
					break;
				case 6:
					this.FTplaceholder = '当前用户岗位';
					break;
				default:
					this.FTplaceholder = '字段值';
					break;
			}
		},
		/**
		 * 验证字段值是否符合选择字段的类型
		 */
		checkFiledType() {
			return new Promise((resolve, reject) => {
				let filedtype = this.interfaceFieldList.filter((item) => item.id == this.entity.interfaceFieId);
				if (filedtype.length > 0) {
					switch (filedtype[0].type) {
						case 1:
							break;
						//int
						case 2:
							if (
								this.entity.filedValue &&
								this.entity.filedValue != '1' &&
								this.entity.filedValue != '0'
							) {
								if (!parseInt(this.entity.filedValue)) {
									reject('字段值格式错误,当前字段值为数字类型');
								}
							}
							break;
						//long
						case 3:
							if (
								this.entity.filedValue &&
								this.entity.filedValue != '1' &&
								this.entity.filedValue != '0'
							) {
								if (!parseInt(this.entity.filedValue)) {
									reject('字段值格式错误,当前字段值为数字类型');
								}
							}
							break;
						//datetime
						case 4:
							if (this.entity.filedValueType != '1') {
								reject('当前字段为日期类型,请选择正确的字段值字段值类型');
							}
							if (this.entity.filedValue && !Date.parse(this.entity.filedValue)) {
								reject('字段值格式错误,当前字段值为日期类型');
							}
							break;
						//decimal
						case 5:
							if (this.entity.filedValue != '1' && this.entity.filedValue != '0') {
								if (!parseInt(this.entity.filedValue)) {
									reject('字段值格式错误,当前字段值为数字类型');
								}
							}

							break;
						//bool
						case 6:
							if (this.entity.filedValueType != '1') {
								reject('当前字段为Bool类型,请选择正确的字段值类型');
							}
							if (this.entity.filedValue) {
								let u = this.entity.filedValue.toUpperCase();
								if (u != 'TRUE' && u != 'FALSE' && u != '1' && u != '0') {
									reject('字段值格式错误,只能输入0、1、true、false');
								}
							}

							break;
					}
				}

				resolve(true);
			});
		},
		/**
		 * 获取条件相关数据
		 * @param type
		 * @return {Promise<unknown>}
		 */
		getConditionDataDic(type) {
			return new Promise((resolve) => {
				this._dataConditionServiceProxy.getConditionDataDic(type).then((res) => {
					resolve(res);
				});
			});
		},
		async save() {
			if (!this.entity.interfaceFieId) {
				return abp.message.warn('选择字段不能为空');
			}
			if (!this.entity.symbol) {
				return abp.message.warn('比较符不能为空');
			}
			if (!this.entity.filedValueType) {
				return abp.message.warn('字段值类型不能为空');
			}
			if (this.entity.filedValueType == 1 && !this.entity.filedValue) {
				return abp.message.warn('字段值不能为空');
			}
			let errStr = undefined;
			await this.checkFiledType().catch((err) => {
				errStr = err;
			});
			if (errStr) {
				return abp.message.warn(errStr);
			}
			this.success({ data: this.entity, index: this.index });
		},
	},
};
</script>

<style scoped></style>
